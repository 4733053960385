<div class="home d-flex flex-column" *transloco="let t">
    <td-airdrop-announcement class="mb-3" #airdrop [hidden]="!(airdrop.vm$ | async)"></td-airdrop-announcement>
    <td-unclaimed-tokens-announcement class="mb-3" #vesting [hidden]="!(vesting.vm$ | async)?.hasVestingTokensToClaim"></td-unclaimed-tokens-announcement>
    <td-blocked-user class="mb-3" #airdropBlocked [hidden]="!(airdropBlocked?.vm$ | async)?.blocked"></td-blocked-user>
    <td-expiring-domains-warning
        class="mb-3"
        [address]="userData?.address || ''"
        #expiringDomains
        [hidden]="!(expiringDomains.stats$ | async)"
    ></td-expiring-domains-warning>

    <td-table-skeleton [rows]="3" [seeMore]="true" *ngIf="userData === undefined"></td-table-skeleton>

    <td-register-cta
        class="d-block home-item"
        *ngIf="
            userData !== undefined &&
            (userData === null ||
                (userData.secondLevelDomainCount === 0 && userData.totalAuctionCount === 0 && userData.totalBuyOfferCount === 0 && !hasFavourites))
        "
    >
    </td-register-cta>

    <td-dashboard-cta
        class="d-block home-item"
        *ngIf="userData && (userData.secondLevelDomainCount > 0 || userData.totalAuctionCount > 0 || userData.totalBuyOfferCount > 0 || hasFavourites)"
    >
    </td-dashboard-cta>

    <div *ngIf="loading | async" class="d-flex flex-column gap-3">
        <td-feed-skeleton [items]="10"></td-feed-skeleton>
    </div>

    <div [hidden]="loading | async">
        <td-pool-widget [hideWhenNoTokens]="true" *ngIf="airdropStarted"></td-pool-widget>
        <td-pool-cta *ngIf="airdropStarted"></td-pool-cta>
        <td-hot-home-auctions></td-hot-home-auctions>
        <td-home-hot-offers></td-home-hot-offers>
        <td-latest-expired-domains></td-latest-expired-domains>
        <td-latest-buy-offers></td-latest-buy-offers>
        <td-home-purchases></td-home-purchases>
    </div>
</div>
