import { Injectable } from '@angular/core';
import { TezosToolkit } from '@taquito/taquito';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { combineLatest, from, Observable, ReplaySubject, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Logger } from '../../browser/logger';
import { TezosNetwork } from '../models';
import { TezosNetworkService } from '../tezos-network.service';
import { TezosService } from '../tezos.service';

export type YouvesOracleResult = { last_update_timestamp: string; price: BigNumber };

@Injectable({
    providedIn: 'root',
})
export class XTZPriceService {
    private usdPriceStream: ReplaySubject<number>;

    constructor(private tezosService: TezosService, private tezosNetworkService: TezosNetworkService, private log: Logger) {}

    convert(amount: number): Observable<number> {
        if (!this.usdPriceStream) {
            this.usdPriceStream = new ReplaySubject(1);
            timer(0, 10 * 60 * 1000)
                .pipe(
                    switchMap(() => combineLatest([this.tezosService.tezosToolkit, this.tezosNetworkService.activeNetwork])),
                    switchMap(([tezos, network]) => from(this.getTezosPrice(tezos, network)))
                )
                .subscribe(([price, updated]) => {
                    if (updated.isBefore(dayjs().subtract(2, 'hours'))) {
                        this.log.warn(`[XTZPriceService] Last update ${updated.fromNow()} (${updated.toISOString()}). Not using outdated value.`);
                    } else {
                        this.usdPriceStream.next(price / 1e6);
                    }
                });
        }

        return this.usdPriceStream.pipe(map(price => amount * price));
    }

    private async getTezosPrice(tezos: TezosToolkit, network: TezosNetwork): Promise<[number, dayjs.Dayjs]> {
        if (!network.youvesOracleContract) {
            return [0, dayjs()];
        }

        const contract = await tezos.wallet.at(network.youvesOracleContract);

        try {
            const data = (await contract.contractViews.get_price_with_timestamp('XTZUSDT').executeView({ viewCaller: contract.address })) as YouvesOracleResult;
            const priceData = this.mapPriceViewData(data);

            return [priceData.computedPrice, priceData.lastUpdateTime];
        } catch (ex) {
            this.log.error('[XTZPriceService] Unable to fetch price from Youves contract', ex);

            return [0, dayjs()];
        }
    }

    private mapPriceViewData(data: YouvesOracleResult) {
        //on this contract, the last_update_timestamp comes back as ms from epoch while taquito expects seconds since epoch
        const timestamp = new Date(data.last_update_timestamp).getTime() / 1000;

        return {
            lastUpdateTime: dayjs(timestamp),
            computedPrice: data.price.toNumber(),
        };
    }
}
