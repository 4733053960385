import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { EventsModule } from '../events/events.module';
import { FileProcessingModule } from '../file-processing/file-processing.module';
import { SharedModule } from '../shared/shared.module';
import { AccountWidgetComponent } from './account-widget.component';
import { AccountComponent } from './account.component';
import { GlobalAnnouncementComponent } from './global-announcement.component';
import { OfflineComponent } from './offline.component';
import { OutdatedDataComponent } from './outdated-data.component';
import { GovernanceUnavailableComponent } from './governance-unavailable.component';
import { SettingsMenuComponent } from './settings-menu.component';
import { TopNavComponent } from './top-nav.component';
import { UpdateAvailableComponent } from './update-available.component';

@NgModule({
    declarations: [
        TopNavComponent,
        UpdateAvailableComponent,
        OfflineComponent,
        SettingsMenuComponent,
        AccountWidgetComponent,
        AccountComponent,
        OutdatedDataComponent,
        GovernanceUnavailableComponent,
        GlobalAnnouncementComponent,
    ],
    imports: [SharedModule, FormsModule, EventsModule, FileProcessingModule],
    exports: [TopNavComponent, UpdateAvailableComponent, OfflineComponent, OutdatedDataComponent, GlobalAnnouncementComponent, GovernanceUnavailableComponent],
})
export class LayoutModule {}
