<ng-container *transloco="let t">
    <td-error *ngIf="errorMessage" [message]="errorMessage" [title]="t(errorTitleKey)"></td-error>
    <td-error *ngIf="nodeError" [message]="t('domain-detail.node-error-message')" [title]="t('general.error')"></td-error>
    <ng-container *ngIf="((dataSource.initialLoading$ | async) || !(!isSecondLevel || acquisitionInfo)) && !errorMessage && !nodeError">
        <td-title-skeleton></td-title-skeleton>
        <td-data-card-skeleton [rows]="4" [buttons]="1"></td-data-card-skeleton>
        <td-data-card-skeleton [rows]="2" [buttons]="1"></td-data-card-skeleton>
        <td-data-card-skeleton [rows]="1" [buttons]="1"></td-data-card-skeleton>
    </ng-container>
    <div class="d-flex flex-column gap-4" *ngIf="(!isSecondLevel || acquisitionInfo) && domain && (dataSource.initialLoading$ | async) === false">
        <h1 class="d-flex align-items-center mb-0">
            <td-address
                *ngIf="reverseRecord"
                [address]="reverseRecord.address"
                [reverseRecord]="reverseRecord"
                [iconSize]="48"
                [text]="false"
                class="me-3"
            ></td-address>
            <div class="text-truncated" [style.minWidth]="'calc(100% - 64px)'">
                <td-domain [domain]="domain.name" [copy]="true" [link]="false" [favourite]="true" [profileFor]="reverseRecord?.address"></td-domain>
            </div>
        </h1>
        <ng-container *ngIf="acquisitionInfo">
            <ng-container *ngIf="canClaim">
                <div *ngIf="wallet" class="card smooth-shadow p-3 mb-2">
                    <td-dns-bridge (finished)="domainClaimed()" class="d-flex" [name]="domain.name"></td-dns-bridge>
                </div>
                <td-connect-cta *ngIf="wallet === null" [title]="t('domain-detail.dns-bridge.not-connected-cta-title', { name: domain.name })"></td-connect-cta>
            </ng-container>
            <ng-container *ngIf="acquisitionInfo.acquisitionState === states.Unobtainable">
                <td-alert state="info">
                    <div class="mb-2">{{ t('domain-detail.unobtainable') }}</div>
                    <div *ngIf="acquisitionInfo.unobtainableDetails.launchDate">
                        <span>{{ t('domain-detail.unobtainable-launch') }}</span>
                        <td-countdown class="ms-1" [value]="acquisitionInfo.unobtainableDetails.launchDate" (zero)="reload()"></td-countdown>
                    </div>
                    <div *ngIf="!acquisitionInfo.unobtainableDetails.launchDate">
                        <a [attr.href]="'learn/schedule' | landingPageUrl">{{ t('general.learn-more') }}</a>
                    </div>
                </td-alert>
            </ng-container>
            <ng-container *ngIf="acquisitionInfo.acquisitionState === states.CanBeBought">
                <div *ngIf="wallet" class="card smooth-shadow p-3 mb-2">
                    <td-buy
                        (bought)="reload()"
                        [name]="domain.name"
                        [acquisitionInfo]="acquisitionInfo"
                        class="d-block d-sm-flex"
                        [ngClass]="{ 'full-card-width': media.isActive('lt-sm') }"
                    ></td-buy>
                </div>
                <td-connect-cta
                    *ngIf="wallet === null"
                    [title]="t('domain-detail.not-connected-cta-title', { name: domain.name, price: pricePerYear })"
                ></td-connect-cta>
            </ng-container>
            <ng-container
                *ngIf="
                    acquisitionInfo.acquisitionState === states.CanBeAuctioned ||
                    acquisitionInfo.acquisitionState === states.AuctionInProgress ||
                    acquisitionInfo.acquisitionState === states.CanBeSettled
                "
            >
                <td-auction
                    (settled)="reload()"
                    (bidPlaced)="reload()"
                    (check)="reload()"
                    [name]="domain.name"
                    [acquisitionInfo]="acquisitionInfo"
                    [auction]="auction"
                ></td-auction>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="showDetails">
            <div class="detail-data card smooth-shadow d-flex flex-column gap-3">
                <div class="d-flex">
                    <h2 class="flex-grow-1">{{ t('domain-detail.basic-information-subtitle') }}</h2>
                    <div class="d-flex justify-items-center align-items-center" *ngIf="claimedByOther">
                        <button mat-stroked-button color="primary" type="button" (click)="claimDomain()">
                            {{ t('domain-detail.claim-button') }}
                        </button>
                        <fa-icon
                            icon="question-circle"
                            class="help ms-1 no-underline"
                            [matTooltip]="t('domain-detail.claim-button-help', { name: domain.name })"
                            size="1x"
                        ></fa-icon>
                    </div>
                </div>
                <div *ngIf="!isSecondLevel && parentName" class="d-flex flex-column align-content-start flex-sm-row align-content-sm-center">
                    <div class="mat-body-strong description">{{ t('fields.parent') }}</div>
                    <div class="flex-grow-1 d-flex align-items-center"><td-domain [domain]="parentName"></td-domain></div>
                </div>
                <div class="d-flex flex-column align-content-start flex-sm-row align-content-sm-center">
                    <div class="mat-body-strong description">{{ t('fields.domain-owner') }}</div>
                    <div class="flex-grow-1 d-flex align-items-center">
                        <td-address *ngIf="domain.owner" [address]="domain.owner" [reverseRecord]="domain.ownerReverseRecord"></td-address>
                        <div *ngIf="!domain.owner">{{ t('domain-detail.not-owned') }}</div>
                    </div>
                </div>
                <div *ngIf="domain.owner" class="d-flex flex-column align-content-start flex-sm-row align-content-sm-center">
                    <div class="mat-body-strong description">{{ t('fields.domain-address') }}</div>
                    <div class="flex-grow-1 d-flex align-items-center">
                        <td-address
                            *ngIf="domain.address"
                            [address]="domain.address"
                            [link]="false"
                            [reverseRecord]="domain.addressReverseRecord"
                            text="address-only"
                        ></td-address>
                        <div *ngIf="!domain.address">{{ t('general.not-set') }}</div>
                    </div>
                </div>
                <div *ngIf="domain.address" class="text-wrap w-100">
                    <fa-icon icon="check" class="txt-success me-1" size="lg"></fa-icon>
                    <span [innerHTML]="t('domain-detail.address-assigned-info', { address: domain.address | escape, name: domain.name })"></span>
                </div>
                <div *ngIf="canEdit && !domain.address" class="text-wrap w-100">
                    <fa-icon icon="times" class="txt-danger" size="lg"></fa-icon>
                    {{ t('domain-detail.address-not-assigned-info') }}
                </div>
                <div *ngIf="domain.data?.length">
                    <h3>{{ t('general.data-title') }}</h3>
                    <td-data-viewer [data]="domain.data!"></td-data-viewer>
                </div>
                <div class="d-flex flex-column flex-sm-row gap-3 align-items-stretch align-items-sm-start">
                    <ng-container *ngIf="canEdit">
                        <button mat-flat-button color="primary" (click)="edit()" size="default"><fa-icon icon="edit"></fa-icon>{{ t('actions.edit') }}</button>
                        <button mat-flat-button color="primary" (click)="transfer()" size="default">
                            <fa-icon icon="people-arrows"></fa-icon>{{ t('actions.transfer-ownership') }}
                        </button>
                        <div
                            class="sell-domain-wrapper"
                            [matTooltip]="t('domain-detail.sell-disabled-expiring-soon')"
                            [matTooltipDisabled]="!isExpiringSoon"
                            [ngClass]="{ 'no-underline': !isExpiringSoon }"
                            *ngIf="canSellDomain"
                            class="d-flex flex-column align-items-stretch align-items-sm-start"
                        >
                            <button mat-flat-button color="primary" (click)="placeOffer()" size="default" [disabled]="isExpiringSoon">
                                <fa-icon icon="comment-dollar"></fa-icon>
                                <ng-container>{{ t('actions.create-offer') }}</ng-container>
                            </button>
                        </div>
                        <button mat-flat-button color="primary" (click)="permissions()" size="default" *ngIf="canChangeOperators" class="js-permissions-btn">
                            <fa-icon icon="users"></fa-icon>{{ t('actions.permissions', { operatorCount: domain.operators?.length }) }}
                        </button>
                    </ng-container>
                    <button
                        *ngIf="wallet !== undefined && offer && isSecondLevel && offer.seller !== wallet?.address"
                        mat-flat-button
                        color="primary"
                        (click)="executeOffer()"
                        size="default"
                    >
                        <div class="d-flex justify-content-center">
                            <fa-icon icon="comment-dollar"></fa-icon>
                            <div [tdDynamicHtml]="t('actions.buy-domain-for', { price: offer.price })"></div>
                        </div>
                    </button>
                    <td-buy-offer-actions
                        *ngIf="isSecondLevel && !canEdit && !!this.domain?.tokenId && !buyOffer"
                        [wallet]="wallet"
                        [domain]="domain"
                        [buyOffer]="buyOffer"
                        (offerActedOn)="offerActedOn()"
                    ></td-buy-offer-actions>
                </div>
            </div>
            <div class="detail-data card smooth-shadow d-flex flex-column gap-3" *ngIf="buyOffer">
                <h2 class="mb-0">
                    {{ t('domain-detail.buy-offer.title') }}
                </h2>
                <div class="d-flex flex-column align-content-start flex-sm-row align-content-sm-center">
                    {{ t('domain-detail.buy-offer.info', { name: domain.name }) }}
                    <td-tez class="ms-2 fw-bold" [value]="buyOffer.priceWithoutFee"></td-tez>
                </div>
                <div class="d-flex flex-column align-content-start flex-sm-row align-content-sm-center">
                    <!-- t(actions.buy-offer.withdraw) -->
                    <td-buy-offer-actions
                        *ngIf="isSecondLevel && !!this.domain?.tokenId"
                        [wallet]="wallet"
                        [domain]="domain"
                        [buyOffer]="buyOffer"
                        [withdrawText]="'actions.buy-offer.withdraw'"
                        (offerActedOn)="offerActedOn()"
                    ></td-buy-offer-actions>
                </div>
            </div>
            <div class="detail-data card smooth-shadow d-flex flex-column gap-3" *ngIf="offer && offer.seller === wallet?.address">
                <h2 class="mb-0">
                    {{ t('domain-detail.offer.title') }}
                </h2>
                <div
                    *ngIf="offer.expiration"
                    [tdDynamicHtml]="t('domain-detail.offer.info-until', { endDate: offer.expiration | dayjsDate, amount: offer.price })"
                ></div>
                <div *ngIf="!offer.expiration" [tdDynamicHtml]="t('domain-detail.offer.info', { amount: offer.price })"></div>
                <div class="d-flex flex-column flex-sm-row gap-3 align-items-stretch align-items-sm-start">
                    <button
                        mat-flat-button
                        color="primary"
                        (click)="editOffer()"
                        *ngIf="[offerStates.Active, offerStates.DomainOperatorsContractMissing].includes(offer.state)"
                    >
                        {{ t('actions.edit') }}
                    </button>
                    <button
                        mat-flat-button
                        color="primary"
                        (click)="cancelOffer()"
                        *ngIf="
                            [
                                offerStates.Active,
                                offerStates.DomainOperatorsContractMissing,
                                offerStates.DomainExpired,
                                offerStates.DomainDoesNotExist,
                                offerStates.DomainOperatorsContractMissing,
                                offerStates.OfferSellerDomainOwnerMismatch,
                                offerStates.DomainIsExpiringSoon
                            ].includes(offer.state)
                        "
                    >
                        {{ t('actions.cancel') }}
                    </button>
                </div>
            </div>
            <div class="detail-data card smooth-shadow d-flex flex-column gap-3">
                <h2 class="mb-0">
                    {{ t('domain-detail.reverse-record-subtitle') }}
                    <fa-icon icon="question-circle" class="accent help no-underline" [matTooltip]="t('cta.set-reverse-record.text')" size="sm"></fa-icon>
                </h2>
                <div class="d-flex flex-column align-content-start flex-sm-row align-content-sm-center">
                    <div class="mat-body-strong description">
                        <ng-container *ngIf="reverseRecord">{{ t('general.assigned') }}</ng-container>
                        <ng-container *ngIf="!reverseRecord">{{ t('general.not-assigned') }}</ng-container>
                    </div>
                    <div class="w-100 d-flex align-items-center" *ngIf="reverseRecord">
                        <td-address
                            [route]="['/reverse-record', reverseRecord.address]"
                            [address]="reverseRecord.address"
                            [copy]="false"
                            [reverseRecord]="reverseRecord"
                            text="address-only"
                        ></td-address>
                    </div>
                </div>
                <div *ngIf="reverseRecord" class="text-wrap w-100">
                    <fa-icon icon="check" class="txt-success me-1" size="lg"></fa-icon>
                    <span [innerHTML]="t('domain-detail.reverse-record-assigned-info', { address: domain.address | escape, name: domain.name })"></span>
                </div>
                <ng-container *ngIf="!reverseRecord">
                    <div
                        *ngIf="walletReverseRecord?.domain && canEdit"
                        class="text-wrap"
                        [innerHTML]="
                            t('domain-detail.reverse-assigned-to-other', { name: walletReverseRecord!.domain!.name, address: walletReverseRecord!.address })
                        "
                    ></div>
                    <div *ngIf="!hasActiveWalletAddress && canEdit" class="text-wrap w-100">
                        <ng-container *ngIf="!domain.address; else cannotBeAssignedDefaultMessage">
                            <fa-icon icon="times" class="txt-danger" size="lg"></fa-icon>
                            {{ t('domain-detail.reverse-record-cannot-be-assigned-no-address-info') }}
                        </ng-container>
                        <ng-template #cannotBeAssignedDefaultMessage>
                            {{ t('domain-detail.reverse-record-cannot-be-assigned-different-address-info') }}
                        </ng-template>
                    </div>
                    <div *ngIf="hasActiveWalletAddress && !walletReverseRecord?.domain" class="text-wrap w-100">
                        <fa-icon icon="times" class="txt-danger" size="lg"></fa-icon>
                        {{ t('domain-detail.reverse-record-not-set-info') }}
                    </div>
                </ng-container>
                <div
                    *ngIf="walletReverseRecord !== undefined && (canSetupReverseRecord || canEditReverseRecord)"
                    class="d-flex flex-column flex-sm-row gap-3 align-items-stretch align-items-sm-start"
                >
                    <button
                        *ngIf="canSetupReverseRecord && !walletReverseRecord?.domain"
                        mat-flat-button
                        color="primary"
                        (click)="setReverseRecord()"
                        size="default"
                    >
                        <fa-icon icon="tag"></fa-icon>{{ t('actions.setup') }}
                    </button>
                    <button *ngIf="canEditReverseRecord" mat-flat-button color="primary" (click)="setReverseRecord()" size="default">
                        <fa-icon icon="tag"></fa-icon>{{ t('actions.edit') }}
                    </button>
                    <button
                        *ngIf="canSetupReverseRecord && walletReverseRecord?.domain"
                        mat-flat-button
                        color="primary"
                        (click)="setReverseRecord()"
                        size="default"
                    >
                        <fa-icon icon="tag"></fa-icon>{{ t('actions.change-your-reverse-record') }}
                    </button>
                </div>
            </div>
            <div *ngIf="isSecondLevel && !isClaimedDomain" class="detail-data card smooth-shadow d-flex flex-column gap-3">
                <h2 class="mb-0">{{ t('domain-detail.websites-subtitle') }}</h2>

                <div *ngIf="domainNameTooLongForWebsite; else distributedWebsite">
                    <fa-icon icon="times" class="txt-danger" size="lg"></fa-icon>
                    {{t('domain-detail.website-name-too-long-warning', {tld, length: configuration.maxWebsiteDomainNameLength})}}
                </div>

                <ng-template #distributedWebsite>
                    <div [innerHTML]="t('domain-detail.website-info', { link: decentralizedWebsiteUrl })"></div>
                    <div *ngIf="redirectUrl" class="d-flex">
                        <fa-icon icon="arrow-alt-circle-right" class="txt-success me-1" size="lg"></fa-icon>
                        <div [innerHTML]="t('domain-detail.website-redirected', { url: redirectUrl })"></div>
                    </div>
                    <div *ngIf="!redirectUrl && contentUrl" class="d-flex">
                        <fa-icon icon="check" class="txt-success me-1" size="lg"></fa-icon>
                        <div [innerHTML]="t('domain-detail.website-active', { url: contentUrl })"></div>
                    </div>
                    <div *ngIf="!redirectUrl && !contentUrl">
                        {{ t('domain-detail.website-inactive') }}
                    </div>
                    <div *ngIf="canEdit" class="d-flex flex-column flex-sm-row gap-3 align-items-stretch align-items-sm-start">
                        <button mat-flat-button color="primary" [disabled]="!wallet" (click)="updateWeb()" size="default">
                            <fa-icon icon="globe"></fa-icon>
                            <ng-container *ngIf="!redirectUrl && !contentUrl">{{ t('actions.set-up-website') }}</ng-container>
                            <ng-container *ngIf="redirectUrl || contentUrl">{{ t('actions.update-website') }}</ng-container>
                        </button>
                    </div>
                </ng-template>
            </div>
            <div *ngIf="domain.expires && isSecondLevel" class="detail-data card smooth-shadow d-flex flex-column gap-3">
                <div class="d-flex align-items-center">
                    <h2 class="w-100 mb-0">{{ t('domain-detail.expiration-subtitle') }}</h2>
                    <td-subscribe-button
                        [event]="subscriptionEvent"
                        *ngIf="canSubscribe"
                        [matTooltip]="t('domain-detail.notifications-tooltip')"
                        class="no-underline"
                    ></td-subscribe-button>
                </div>
                <div class="d-flex align-items-center">
                    <div [innerHTML]="t('domain-detail.expiration-info', { date: domain.expires | dayjsDate })"></div>
                    <fa-icon
                        class="ms-1 icon-warning no-underline"
                        icon="exclamation-triangle"
                        *ngIf="canOperate && domainIsExpiring()"
                        [matTooltip]="t('domain.expiring-soon')"
                    ></fa-icon>
                </div>
                <div class="d-flex flex-column flex-sm-row gap-3 align-items-stretch align-items-sm-start">
                    <button mat-flat-button color="primary" [disabled]="!wallet" (click)="renew()" size="default">
                        <fa-icon icon="redo-alt"></fa-icon>{{ t('actions.renew') }}
                    </button>
                </div>
            </div>
            <div class="card smooth-shadow pb-0 d-flex flex-column" *ngIf="canOperate || domain.subdomains?.totalCount">
                <h2>{{ t('domain-detail.title-subdomains') }}</h2>
                <div class="pb-3 d-flex flex-column gap-3">
                    <div class="d-flex flex-column flex-sm-row gap-3 align-items-stretch align-items-sm-start" *ngIf="canOperate">
                        <button mat-flat-button color="primary" (click)="addSubdomain()">
                            <fa-icon icon="plus"></fa-icon>{{ t('actions.add-subdomain') }}
                        </button>
                    </div>
                </div>
                <td-domain-table
                    [hidden]="!domain.subdomains?.totalCount"
                    [baseFilter]="subdomainsFilter"
                    [baseLevel]="subdomainLevel"
                    [columns]="['name', 'actions']"
                    [allowedCategories]="['all', 'direct']"
                    [noShadow]="true"
                    [address]="wallet?.address ?? ''"
                    class="subdomain-table full-card-width"
                ></td-domain-table>
            </div>
        </ng-container>
    </div>
</ng-container>
