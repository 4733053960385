import { AccountInfo, BeaconEvent, ColorMode, ExtendedPeerInfo, NetworkType, PeerInfo, PermissionScope } from '@airgap/beacon-sdk';
import { Injectable } from '@angular/core';
import { BeaconWallet } from '@taquito/beacon-wallet';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, Observable, ReplaySubject, from, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, distinctUntilKeyChanged, first, map, switchMap, tap } from 'rxjs/operators';
import { TrackingService } from '../../browser/tracking.service';
import { Configuration } from '../../configuration';
import { DarkModeService } from '../../shared/dark-mode/dark-mode.service';
import { StorageSchema } from '../../utils/storage';

import { BeaconService } from '../integration/beacon.service';
import { TezosBeaconWallet, TezosNetwork } from '../models';
import { TezosNetworkService } from '../tezos-network.service';

@Injectable({
    providedIn: 'root',
})
export class TezosBeaconWalletManager {
    private walletStream = new ReplaySubject<TezosBeaconWallet | null>(1);
    private _activeAccount = new BehaviorSubject<AccountInfo | undefined>(undefined);
    private _beaconWallet: BeaconWallet | null = null;
    private stringToNetworkType: { [key: string]: NetworkType } = {
        mainnet: NetworkType.MAINNET,
        ghostnet: NetworkType.GHOSTNET,
    };

    readonly activeWallet = this.walletStream.pipe(distinctUntilChanged((prev, curr) => prev?.address === curr?.address));

    get beaconWallet(): BeaconWallet | undefined {
        return this._beaconWallet ?? undefined;
    }

    constructor(
        private tezosNetworkService: TezosNetworkService,
        private beaconService: BeaconService,
        private trackingService: TrackingService,
        private darkModeService: DarkModeService,
        private cookieService: CookieService,
        private configuration: Configuration
    ) {
        this.connect(true).subscribe();
        this.darkModeService.theme$.subscribe(theme => {
            if (this._beaconWallet) {
                this._beaconWallet.client.setColorMode(theme === 'dark' ? ColorMode.DARK : ColorMode.LIGHT);
            }
        });
    }

    connect(isStartup = false): Observable<void> {
        return this.tezosNetworkService.activeNetwork.pipe(
            first(),
            switchMap(network =>
                this.setupWallet(network).pipe(
                    distinctUntilKeyChanged('activeAccount'),
                    map(w => ({ walletData: w, network }))
                )
            ),
            switchMap(({ walletData, network }) => {
                if (!walletData.activeAccount && !isStartup) {
                    //trigger beacon popup with list of wallets
                    return this.requestPermissions(network, walletData.wallet).pipe(
                        map(response => ({
                            walletData: { activeAccount: response.activeAccount ?? walletData.activeAccount, wallet: walletData.wallet },
                            connectedAddress: response.permissionResponse.address,
                        }))
                    );
                }
                return of({ connectedAddress: walletData.activeAccount?.address, walletData });
            }),
            switchMap(({ connectedAddress, walletData }) =>
                //if there is a connected account, get the connected peers to get name/icon details
                connectedAddress
                    ? from(this.getActivePeer(<any>walletData)).pipe(map(peer => ({ peer, connectedAddress })))
                    : of({ peer: null, connectedAddress })
            ),
            switchMap(({ connectedAddress, peer }) => {
                if (connectedAddress && peer) {
                    this.trackingService.event('login', { method: 'beacon-sdk' });

                    this.walletStream.next({
                        address: connectedAddress,
                        icon: (<any>peer).icon ?? '',
                        walletName: peer.name ?? '',
                    });

                    this.cookieService.put(StorageSchema.connectStatus.key, 'connected', {
                        domain: this.configuration.cookieDomain,
                        expires: new Date(new Date().getTime() + 90 * 24 * 60 * 60 * 1000),
                    });
                } else {
                    this.walletStream.next(null);
                    this.clearConnectedCookie();
                }

                return of(void 0);
            }),
            catchError(err => {
                this.trackingService.exception('');
                return throwError(() => err);
            })
        );
    }

    disconnect(): Observable<void> {
        if (!this._beaconWallet) {
            return of();
        }

        return from(this._beaconWallet.clearActiveAccount()).pipe(
            tap(() => {
                this.walletStream.next(null);
                this.clearConnectedCookie();
            })
        );
    }

    private clearConnectedCookie() {
        this.cookieService.remove(StorageSchema.connectStatus.key, {
            domain: this.configuration.cookieDomain,
        });
    }

    private setupWallet(network: TezosNetwork): Observable<{ wallet: BeaconWallet; activeAccount: AccountInfo | undefined }> {
        if (!this._beaconWallet) {
            this._beaconWallet = this.beaconService.createWallet(this.getNetworkType(network.name));
            this.listenForAccountInfo(this._beaconWallet);
        }

        return this._activeAccount.pipe(
            map(account => ({
                wallet: this._beaconWallet!,
                activeAccount: account,
            }))
        );
    }

    private listenForAccountInfo(beaconWallet: BeaconWallet) {
        beaconWallet.client.subscribeToEvent(BeaconEvent.ACTIVE_ACCOUNT_SET, accountInfo => {
            this._activeAccount.next(accountInfo);
        });
    }

    private requestPermissions(network: TezosNetwork, wallet: BeaconWallet) {
        const type = this.getNetworkType(network.name);

        return from(
            wallet.client.requestPermissions({
                scopes: [PermissionScope.OPERATION_REQUEST, PermissionScope.SIGN],
                network: { type, rpcUrl: type === NetworkType.CUSTOM ? network.rpcUrl : undefined },
            })
        ).pipe(
            switchMap(permissionResponse =>
                this._activeAccount.pipe(
                    map(activeAccount => ({
                        permissionResponse,
                        activeAccount,
                    }))
                )
            )
        );
    }

    private async getActivePeer({ wallet, activeAccount }: { wallet: BeaconWallet; activeAccount: AccountInfo | undefined }): Promise<PeerInfo | null> {
        const peers = (await wallet.client.getPeers()) as ExtendedPeerInfo[];

        if (!activeAccount) {
            return null;
        }

        let activePeer = peers.find(p => p.senderId === activeAccount.senderId || p.senderId === activeAccount.origin.id);
        if (!activePeer) {
            activePeer = peers.find(p => (p as any).extensionId === activeAccount.origin.id);
        }

        return activePeer ?? null;
    }

    private getNetworkType(name: string): NetworkType {
        return this.stringToNetworkType[name] ?? NetworkType.CUSTOM;
    }
}
